.model-choice-rhombus-wrapper {
  display: flex;
  cursor: pointer;
  justify-content: center;
  transition: 0.3s;
}

.model-choice-rhombus {
  background-color: #777;
  width: 130px;
  height: 230px;
  position: relative;
}

.model-choice-image {
  background-size: cover;
  background-position: center 70%;
  width: 100%;
  height: 100%;
  filter: brightness(70%) hue-rotate(20deg);
}

.model-choice-image-selected {
  filter: brightness(100%) hue-rotate(20deg) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
}

.model-choice-rhombus:hover .model-choice-image {
  transform: scale(1.05);
  transition: 0.3s;
}

.model-choice-rhombus::after {
  content: attr(data-hover-text);
  position: absolute;
  bottom: -5px;
  margin-left: 5%;
  margin-bottom: -38px;
  text-align: center;
  opacity: 0;
  transition: 0.3s;
  font-size: 13px;
  width: 120px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.model-choice-rhombus-wrapper.selected .info-icon,
.model-choice-rhombus-selected::after {
  opacity: 1;
  transition: 0.3s;
}

.model-choice-rhombus:hover::after {
  transition: 0.3s;
  opacity: 1;
}

.info-icon {
  position: absolute;
  top: 7px; /* Adjust as needed */
  left: 7px; /* Adjust as needed */
  width: 18.5px;
  height: 18.5px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid black;
  box-sizing: border-box;
  padding-top: 1px;
  opacity: 0;
  transition: 0.3s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.model-choice-rhombus-wrapper:hover .info-icon {
  opacity: 1;
  transition: 0.3s;
}

@media (orientation: landscape) and (max-height: 480px) {
  .model-choice-rhombus::after {
    margin-left: -12px;
    font-size: 10px;
    margin-bottom: -20px;
  }
  .model-choice-rhombus {
    width: 95px;
    height: 150px;
  }
  .info-icon {
    top: 5px; 
    left: 5px; 
  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .model-choice-rhombus {}
  .model-choice-rhombus::after {
    margin-left: -12px;
    font-size: 10px;
    margin-bottom: -20px;
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .model-choice-rhombus {}
  .model-choice-rhombus::after {
    margin-left: -12px;
    font-size: 10px;
    margin-bottom: -20px;
  }
}


@media screen and (min-resolution: 144dpi) {
  .model-choice-rhombus {
    width: 100px; /* 130px * 1.5 */
    height: 200px; /* 230px * 1.5 */
  }
  .model-choice-rhombus::after {
    font-size: 11px; /* 13px * 1.5 */
    width: 100px; /* 120px * 1.5 */
    margin-left: 0%;
  }
  .info-icon {
    top: 7px; /* 7px * 1.5 */
    left: 7px; /* 7px * 1.5 */
    width: 18.5px; /* 18.5px * 1.5 */
    height: 18.5px; /* 18.5px * 1.5 */
    line-height: 16px; /* 16px * 1.5 */
    font-size: 14px; /* 14px * 1.5 */
  }
}